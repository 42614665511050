import React from 'react';
import { shape, string } from 'prop-types';

import { Tag } from '@andes/tag';
import { CarouselFreeSlide } from '@andes/carousel-free';

import Link from '../../../../link';
import { namespace } from '../constants';

const ColorCarouselSlide = ({ slide }) => {
  const { id, target, label, value_id } = slide;
  const { text } = label;

  return (
    <CarouselFreeSlide key={id} className={`${namespace}-color-item-slide`}>
      <Link className={`${namespace}__link`} href={target} filterId={id} title={text}>
        <Tag asset={{ text: ' ' }} label={text} className={`${namespace}-tag-color-${value_id}`} />
      </Link>
    </CarouselFreeSlide>
  );
};

ColorCarouselSlide.propTypes = {
  slide: shape({
    id: string,
    label: shape({
      text: string,
    }),
    target: string,
    value_id: string,
  }),
};

export default ColorCarouselSlide;
