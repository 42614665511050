import React, { useState, useEffect, useRef, useCallback } from 'react';

import classnames from 'classnames';
import { Tag } from '@andes/tag';
import { CarouselFree, CarouselFreeSlide } from '@andes/carousel-free';

import Link from '../../../link';
import { namespace, SPACING, textCarouselPropTypes } from './constants';

const TextCarousel = ({ filter }) => {
  const [loading, setLoading] = useState(true);
  const ref = useRef(null);

  const handleScrollToElement = useCallback(() => {
    if (ref.current) {
      const element = ref.current.querySelectorAll(`.${namespace}__link`)[filter.scroll_position || 0];

      if (element) {
        element.scrollIntoView({ block: 'nearest', inline: 'center' });
      }
    }
  }, [ref, filter.scroll_position]);

  useEffect(() => {
    handleScrollToElement();
    setLoading(false);
  }, [filter.scroll_position, handleScrollToElement]);

  return (
    <div ref={ref}>
      <CarouselFree spacing={SPACING} className={`${namespace}-carousel`}>
        {filter.values.map((slide) => (
          <CarouselFreeSlide key={slide.id} className={`${namespace}-text-item-slide`}>
            <Link className={`${namespace}__link`} href={slide.target} title={slide.label.text}>
              <Tag className={classnames({ skeleton: loading })} label={slide.label.text} />
            </Link>
          </CarouselFreeSlide>
        ))}
      </CarouselFree>
    </div>
  );
};

TextCarousel.propTypes = {
  ...textCarouselPropTypes,
};

export default TextCarousel;
