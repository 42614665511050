import React, { useMemo } from 'react';

import { CarouselSnapped } from '@andes/carousel-snapped';

import useModal from '../../../../hooks/use-modal';
import SearchModalContainer from '../../../sidebar/components/search-modal';
import Modal from '../../../modal/components/base-modal';
import useCarouselBreakpoints from '../../../../hooks/use-carousel-breakpoints';
import { useCurrentLayout } from '../../../../components/context/layout';
import {
  namespace,
  BRANDS_TYPE,
  DEVICE_TYPE_TABLET,
  SLIDES_LIMIT,
  SLIDES_TO_SHOW_TABLET,
  SPACING,
  SpecializedCarouselDesktopPropsTypes,
} from './constants';
import { renderSlidesDesktop as renderSlides } from './components';

const SpecializedCarousel = ({ filter, title, deviceType }) => {
  const updatedFilterValues = useMemo(() => {
    const values = Array.from(filter.values);

    if (filter.see_all_text) {
      values.push(filter.see_all_text);
    }

    return values;
  }, [filter.values, filter.see_all_text]);

  const currentLayout = useCurrentLayout();
  const totalSlides = updatedFilterValues.length;
  const hasNineSlides = totalSlides === 9;

  const { slidesToShow } = useCarouselBreakpoints(
    BRANDS_TYPE,
    currentLayout?.id,
    hasNineSlides ? SLIDES_LIMIT : totalSlides,
  );

  const [isOpen, openModal, hideModal] = useModal();
  const isTablet = deviceType === DEVICE_TYPE_TABLET;

  return (
    <>
      <CarouselSnapped
        pagination={false}
        className={`${namespace}-carousel`}
        slidesPerView={isTablet ? SLIDES_TO_SHOW_TABLET : slidesToShow}
        spacing={SPACING}
        strictBoundaries={false}
      >
        {updatedFilterValues.map((slide, index) => renderSlides(slide, index, openModal))}
      </CarouselSnapped>

      {isOpen && (
        <div className={`${namespace}-specialized-see-more-modal`}>
          <Modal
            className="ui-search-modal--default"
            modalUrl="#filter"
            type="small"
            withLink={false}
            open={isOpen}
            openModal={openModal}
            hideModal={hideModal}
            modalTitle={title.text}
          >
            <SearchModalContainer values={filter.expanded_values} id={filter.id} type="WITH_SEARCH" {...filter.modal} />
          </Modal>
        </div>
      )}
    </>
  );
};

SpecializedCarousel.propTypes = {
  ...SpecializedCarouselDesktopPropsTypes,
};

export default SpecializedCarousel;
