import React from 'react';

import classnames from 'classnames';
import { Image } from 'nordic/image';
import { Thumbnail } from '@andes/thumbnail';
import { CarouselFreeSlide } from '@andes/carousel-free';

import Link from '../../../../link';
import StyledLabelWrapper from '../../../../../components/styled-label/styled-label';
import AvailableFilters from '../../../../../components/filters/available/available-filters.mobile';
import {
  BRAND_TYPE,
  SEE_MORE,
  BRAND_THUMBNAIL_SIZE,
  DEFAULT_THUMBNAIL_SIZE,
  LAZYLOAD_THRESHOLD,
  namespace,
} from '../constants';
import { isTitleOneLine } from '../../helpers';

const getThumbnailSize = (filterId, thumbnailSize) =>
  filterId === BRAND_TYPE ? thumbnailSize || BRAND_THUMBNAIL_SIZE : DEFAULT_THUMBNAIL_SIZE;

export const renderSlidesMobile = (slide, index, filterId, isTopBrand, availableFilters, title) => {
  const thumbnailSize = getThumbnailSize(filterId, slide.thumbnail_size);

  if (slide.id !== SEE_MORE) {
    return (
      <CarouselFreeSlide key={slide.id} className={`${namespace}-specialized-item-slide`}>
        <Link className={`${namespace}__link`} href={slide.target} filterId={slide.id} title={slide.picture.tags.alt}>
          <Thumbnail size={Number(thumbnailSize)}>
            <Image
              alt=""
              lazyload={index < LAZYLOAD_THRESHOLD ? 'off' : 'on'}
              preload={index < LAZYLOAD_THRESHOLD}
              src={slide.picture.normal}
            />
          </Thumbnail>
          <StyledLabelWrapper
            className={classnames(
              `${namespace}${
                isTitleOneLine(slide.label.text) ? '-specialized-title-short' : '-specialized-title-large'
              }`,
            )}
            color={slide.label.color}
            font_weight={slide.label.font_family}
            size={slide.label.font_size}
            text={slide.label.text}
            as="span"
          />
          {slide.subtitle && <span className={`${namespace}-specialized-subtitle`}>{slide.subtitle.text}</span>}
        </Link>
      </CarouselFreeSlide>
    );
  }

  return (
    <CarouselFreeSlide key={slide.id} className={`${namespace}-specialized-item-slide`}>
      <Thumbnail size={Number(thumbnailSize)}>
        <Image
          alt={slide.picture.tags.alt}
          lazyload={index < LAZYLOAD_THRESHOLD ? 'off' : 'on'}
          preload={index < LAZYLOAD_THRESHOLD}
          src={slide.picture.normal}
        />
      </Thumbnail>
      {slide.subtitle && <span className={`${namespace}-specialized-subtitle`}>{slide.subtitle.text}</span>}
      <AvailableFilters
        title={title.text}
        labels={slide.label}
        icon={false}
        filters={availableFilters}
        isTopBrand={isTopBrand}
        isExpanded
        srLabel={title || ''}
      />
    </CarouselFreeSlide>
  );
};
